var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var savedItemService = require('../../../lib/js/savedItemsService.js');

function ProductFilterV2() {
    var _this = this;
    var koModel, koModelProductToggle, koModelMapGalleryToggle, _filterValues = {};
    var animateRunning = false;
    var _citiesDict, _selectedProduct;

    var viewModel = function (options) {
        var self = this;

        self.viewLookupTable = options.viewLookupTable
        self.productToggleOptions = options.productToggleOptions;
        self.filterRef = options.filterRef;
        self.viewObservable = {
            productType: ko.observable(options.productToggleOptions.communityToggleLabel),
            currentProductTotalCount: ko.observable(0),
            currentProductMatchCount: ko.observable(0),
            currentProductNoMatchCount: ko.observable(0),
            qmiMatchCount: ko.observable(0),
            communityMatchCount: ko.observable(0),
            totalCommunities: ko.observable(0),
            totalQMI: ko.observable(0)
        };

        self.toggleChecked = {
            community: ko.observable(true),
            qmi: ko.observable(false)
        }

        self.toggleMapGallerySelect = {
            map: ko.observable(true),
            gallery: ko.observable(false)
        }

        self.filterView = function () {
            self.filterRef.onApply(self.viewLookupTable[self.viewObservable.productType()]);
        }

        // Toggle between community and qmi view - current view stored in view observable object in product type - triggers an event and passes product to summary model to notify of change 
        self.toggleProduct = function (_, event, label) {
            // this will need to be updated when we add the home options filter to the map sidebar
            var toggledLabel;
          
            if (label) {
                toggledLabel = label;
            } else {
                toggledLabel = $(event.target).attr("for");
            }

            self.toggleChecked.community(toggledLabel == "r-Communities");
            self.toggleChecked.qmi(toggledLabel == "r-QMIs");
            var updatedToggle = self.toggleChecked.community() ? self.productToggleOptions.communityToggleLabel : self.productToggleOptions.qmiToggleLabel;
            _selectedProduct = updatedToggle;
            self.viewObservable.productType(updatedToggle);

            $(".pf-toggle-listener")
                .trigger("pf-view-change", [self.viewLookupTable[self.viewObservable.productType()]])
                .trigger("compare-view-change", self.viewObservable.productType());

            $(".pfv2-listener")
                .trigger("pf-view-change", [self.viewLookupTable[self.viewObservable.productType()]]);

            savedItemService.setSavedStateForDocument();

            if (_filterValues[_selectedProduct]) {
                setFilterValues(_selectedProduct);
                self.filterRef.handleFilterCount();
                self.filterRef.handleSliderStyling();
            }
            else {
                self.filterRef.onReset(true);
                getFilterValues();
                self.filterRef.handleSliderStyling();
            }

            applySavedExactAndCloseMatchValues();
        }

        self.resetFilter = function () {
            self.filterRef.onReset();
        }

        self.toggleMapGallery = function (item, e) {
            var $target = $(e.target);
            var forLabel = $target.attr('for');

            var $jumpToCommunityGallery = $('[data-community-gallery-jump="ProductSummaryV2CommunityGallery"]');
            var $jumpToQMIGallery = $('[data-qmi-gallery-jump="ProductSummaryV2QMIGallery"]');
            var $jumpToMap = $('[data-map-jump="ProductSummaryV2Map"]');

            var $scrollToElement = forLabel == 'r-map' ? $jumpToMap : 
                koModelProductToggle.toggleChecked.qmi() ? $jumpToQMIGallery : $jumpToCommunityGallery
            
            if (forLabel == 'r-map') {
                self.toggleMapGallerySelect.map(true);
                self.toggleMapGallerySelect.gallery(false);
            } else if (forLabel == 'r-gallery') {
                self.toggleMapGallerySelect.map(false);
                self.toggleMapGallerySelect.gallery(true);
            }

            var offset = 0;
            var $filterElement = item.filterRef.$element;
            
            if ($filterElement && $filterElement.length) {
                offset = $filterElement.height();
            }

            if ($scrollToElement && $scrollToElement.length) {
                animateRunning = true;
                $('html, body').animate({
                    scrollTop: $scrollToElement.first().offset().top - offset
                }, 1000, function() {
                    animateRunning = false;
                });
            }
        }
    }

    this.initialize = function (element, options) {
        var self = this;
        var $window = $(window);

        $window.on("components.loaded", function () {
            $window.trigger("Header.disableFixed");
        });

        setLocationTitleWhenCityQueryIsPresent();

        var $filterBtn = this.$element.find(".ProductFilterV2__management-filter-btn");
        var $filterPriceBtn = this.$element.find(".ProductFilterV2__Price-management-filter-btn");
        var $mobileClose = $("#filterNavIconCloseMobile");
        var $mobileCloseStates = $("#filterStatesIconCloseMobile");
        var $toggleInputs = this.$element.find(".ProductFilterV2__management-toggles");
        var $bedroomInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $bathroomInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var $mapToggleLabel = $("#r-map-label");
        var $galleryToggleLabel = $("#r-gallery-label");
        var $homeOptions = this.$element.find(".ProductFilterV2__management-options-checkbox");

        $filterBtn.on('click', this.toggleFilter.bind(this));
        $mobileCloseStates.on('click', this.toggleFilter.bind(this));
        $filterPriceBtn.on('click', this.togglePriceFilter.bind(this));
        $mobileClose.on('click', this.togglePriceFilter.bind(this));
        $bedroomInputs.on('click', utils.debounce(toggleBedroomSelection, 250, false));
        $bathroomInputs.on('click', utils.debounce(toggleBathroomSelection, 250, false));
        $homeOptions.on('click', getFilterValues);

        var $filterCircle = this.$element.find(".ProductFilterV2__management-filter-circle");
        var $filterCloseIcon = $("#filterNavIconClose");
        var $PricefilterCloseIcon = $("#PricefilterNavIconClose");

        $filterCircle.addClass("hide");
        $filterCloseIcon.addClass("hide hide-xs");
        $PricefilterCloseIcon.addClass("hide hide-xs");

        var $filterCircleColor = this.$element.find(".ProductFilterV2__management-filter-circle");
        $filterCircleColor.css({
            "background-color": $filterCircleColor.data("filterCircleColor"),
            "color": $filterCircleColor.data("filterCircleTextColor")
        })

        this.resetFilterValues();

        $window.on("resize", utils.debounce(this.handleFilterLayout.bind(this), 250, false));

        $mapToggleLabel.on("click", function () {
            $galleryToggleLabel.addClass("filter-toggle-unselected");
            $mapToggleLabel.removeClass("filter-toggle-unselected");
        });

        $galleryToggleLabel.on("click", function () {
            $mapToggleLabel.addClass("filter-toggle-unselected");
            $galleryToggleLabel.removeClass("filter-toggle-unselected");
        });

        $(document).ready(function() {
            _this.handleDeeplink();  
            self.$element.affix({
                offset: {
                    top: self.$element.offset().top
                }
            });
        });

        $(window).on('hashchange', function() {
            _this.handleDeeplink();
        });

        var productToggleOptions = {
            communityToggleLabel: $toggleInputs.data("communityToggleLabel"),
            selectedToggleBackgroundColor: $toggleInputs.data("selectedToggleFillColor"),
            selectedToggleColor: $toggleInputs.data("selectedToggleTextColor"),
            qmiToggleLabel: $toggleInputs.data("qmiToggleLabel"),
            unselectedToggleBackgroundColor: $toggleInputs.data("unselectedToggleFillColor"),
            unselectedToggleColor: $toggleInputs.data("unselectedToggleTextColor")
        }

        _selectedProduct = productToggleOptions.communityToggleLabel;

        var viewLookupTable = {}
        viewLookupTable[$toggleInputs.data("communityToggleLabel")] = 'community'
        viewLookupTable[$toggleInputs.data("qmiToggleLabel")] = 'qmi'

        var koModelOptions = {
            productToggleOptions: productToggleOptions,
            viewLookupTable: viewLookupTable,
            filterRef: this
        }
        
        koModel = new viewModel(koModelOptions);
        koModelProductToggle = new viewModel(koModelOptions);
        koModelMapGalleryToggle = new viewModel(koModelOptions);

        var filterElement = document.getElementById('Price-management-view');
        var productToggleElement = document.getElementById('product-filter-v2-management-toggles');
        var mapGalleryToggleElement = document.getElementById('product-map-gallery-toggle');

        ko.applyBindings(koModel, filterElement);
        ko.applyBindings(koModelProductToggle, productToggleElement);
        ko.applyBindings(koModelMapGalleryToggle, mapGalleryToggleElement);

        $window.on('scroll', function () {
            var $jumpToCommunityGallery = $('[data-community-gallery-jump="ProductSummaryV2CommunityGallery"]');
            var $jumpToQMIGallery = $('[data-qmi-gallery-jump="ProductSummaryV2QMIGallery"]');

            var $scrollToGallery = koModelProductToggle.toggleChecked.community() ? 
                $jumpToCommunityGallery : 
                $jumpToQMIGallery;
 
            if ($scrollToGallery && $scrollToGallery.length && !animateRunning) {
                if ($window.scrollTop() >= $scrollToGallery.first().offset().top - 200) {
                    koModelMapGalleryToggle.toggleMapGallerySelect.gallery(true);
                    koModelMapGalleryToggle.toggleMapGallerySelect.map(false);
                } else {
                    koModelMapGalleryToggle.toggleMapGallerySelect.gallery(false);
                    koModelMapGalleryToggle.toggleMapGallerySelect.map(true);
                }
            }
        });

    };

    this.handleDeeplink = function() {
          var deeplink = window.location.hash.substring(1).toLowerCase(); 
          var communityDeeplinkData = $('.ProductSummaryGalleryv2').data();
          var qmiDeeplinkData = $('.ProductSummaryQMIGalleryv2').data();

          var communityDeeplinkMatch = communityDeeplinkData && communityDeeplinkData.deeplink && deeplink === communityDeeplinkData.deeplink.toLowerCase();
          var qmiDeeplinkMatch = qmiDeeplinkData && qmiDeeplinkData.deeplink && deeplink === qmiDeeplinkData.deeplink.toLowerCase();
      
          if (communityDeeplinkMatch || qmiDeeplinkMatch) {
              var label = qmiDeeplinkMatch ? "r-QMIs" : "r-Communities";
              // Call toggleProduct with the determined label
              koModelProductToggle.toggleProduct(null, null, label);
          }
      };

    this.toggleFilter = function () {
        var $body = $("body");
        var $filter = this.$element.find(".ProductFilterV2__management-filter");
        var $filterBtn = this.$element.find(".ProductFilterV2__management-filter-btn");
        var $filterContent = this.$element.find(".ProductFilterV2__management-filter-content");
        var $filterIconClose = $("#filterNavIconClose");
        var $filterIconArrow = $("#filterNavIconArrow");

        var $filterPriceLocation = this.$element.find(".ProductFilterV2__Price-management-view");
        if ($filterPriceLocation.hasClass("filter-open"))
            this.togglePriceFilter();

        $filter.toggleClass("filter-open");
        $filterBtn.toggleClass("filter-button-closed");
        $filterContent.toggleClass("show-filter-content");
        $body.toggleClass("noscroll");

        $filterIconClose.toggleClass("hide");
        $filterIconArrow.toggleClass("hide");

        this.handleFilterLayout();
    }

    this.togglePriceFilter = function () {
        var $body = $("body");
        var $filter = this.$element.find(".ProductFilterV2__Price-management-view");
        var $filterBtn = this.$element.find(".ProductFilterV2__Price-management-filter-btn");
        var $filterContent = this.$element.find(".ProductFilterV2__Price-management-filter-content");
        var $filterIconClose = $("#PricefilterNavIconClose");
        var $filterIconArrow = $("#PricefilterNavIconArrow");

        var $filterLocation = this.$element.find(".ProductFilterV2__management-filter");
        if ($filterLocation.hasClass("filter-open"))
            this.toggleFilter();

        $filter.toggleClass("filter-open");
        $filterBtn.toggleClass("filter-button-closed");
        $filterContent.toggleClass("show-filter-content");
        $body.toggleClass("noscroll");
        $filterIconClose.toggleClass("hide");
        $filterIconArrow.toggleClass("hide");

        this.handleFilterLayout();
    }

    this.initRoomSelection = function () {

        var $bedroomInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $bathroomInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");

        $bedroomInputs.children().first().prop("checked", true);
        $bathroomInputs.children().first().prop("checked", true);

        toggleBedroomSelection();
        toggleBathroomSelection();

    }

    function toggleBedroomSelection() {

        var $toggleInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $selected = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:checked+label");
        var $notSelected = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:not(:checked):not(:disabled)+label");
        var $disabled = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color": $toggleInputs.data("roomFillColor"),
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color": "inherit",
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": "#b9b9b9"
        }

        var disabled = {
            "background-color": "rgba(118, 118, 118, 0.3)",
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": "#b9b9b9"
        }

        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

        getFilterValues();
    }

    function toggleBathroomSelection() {

        var $toggleInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var $selected = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:checked+label");
        var $notSelected = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:not(:checked):not(:disabled)+label");
        var $disabled = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color": $toggleInputs.data("roomFillColor"),
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color": "inherit",
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": "#b9b9b9"
        }

        var disabled = {
            "background-color": "rgba(118, 118, 118, 0.3)",
            "color": $toggleInputs.data("roomTextColor"),
            "border-color": "#b9b9b9"
        }

        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

        getFilterValues();
    }

    this.initHomeOptions = function () {

        var homeOptions = this.$element.find(".ProductFilterV2__management-options-checkbox")
        homeOptions.each(function (i) {
            if (!homeOptions[i].disabled) {
                homeOptions[i].checked = true
            }

        })

    }

    this.initSliders = function () {

        this.initSliderBounds();
        this.initSliderStyling()
        this.handleLowerPrSlider();
        this.handleUpperPrSlider();
        this.handleLowerSqftSlider();
        this.handleUpperSqftSlider();

    }

    this.initSliderBounds = function () {

        var lowerPrSlider = $('#prLower');
        handleSliderBounds(lowerPrSlider,
            parseInt(lowerPrSlider.data("prSliderMin")),
            parseInt(lowerPrSlider.data("prSliderMax")),
            parseInt(lowerPrSlider.data("prSliderMin")))

        var upperPrSlider = $('#prUpper');
        handleSliderBounds(upperPrSlider,
            parseInt(upperPrSlider.data("prSliderMin")),
            parseInt(upperPrSlider.data("prSliderMax")),
            parseInt(upperPrSlider.data("prSliderMax")));

        var lowerSqftSlider = $('#sqftLower');
        handleSliderBounds(lowerSqftSlider,
            parseInt(lowerSqftSlider.data("sqftSliderMin")),
            parseInt(lowerSqftSlider.data("sqftSliderMax")),
            parseInt(lowerSqftSlider.data("sqftSliderMin")))

        var upperSqftSlider = $('#sqftUpper');
        handleSliderBounds(upperSqftSlider,
            parseInt(upperSqftSlider.data("sqftSliderMin")),
            parseInt(upperSqftSlider.data("sqftSliderMax")),
            parseInt(upperSqftSlider.data("sqftSliderMax")))

        lowerPrSlider.on("input", this.handleLowerPrSlider.bind(this))
        upperPrSlider.on("input", this.handleUpperPrSlider.bind(this));
        lowerSqftSlider.on("input", this.handleLowerSqftSlider.bind(this))
        upperSqftSlider.on("input", this.handleUpperSqftSlider.bind(this));

    }

    function setLocationTitleWhenCityQueryIsPresent() {
        var params = utils.getQueryParams();
        if (params && params.cities && typeof (params.cities) === 'string') {
            var validCities = [];
            var cities = params.cities.split(",");
            cities.forEach(function(cityName) {
                var validName = isValidCity(cityName);
                if (validName) 
                    validCities.push(validName);
            });

            if (validCities.length > 0)
                $(".ProductFilterV2__management-filter-title").text(validCities.join(", "));
        }
    }

    function isValidCity(cityName) {
        var formattedName = formatCityName(cityName);

        if (!_citiesDict) {
            _citiesDict = {};
            FindAHomeFilterData.locations.forEach(function(location) {
                location.AllCities.forEach(function(cityName) {
                    _citiesDict[formatCityName(cityName)] = cityName;
                });
            });
        }
        
        return _citiesDict.hasOwnProperty(formattedName) ? _citiesDict[formattedName] : false;

        function formatCityName(name) { return name.toLowerCase().replaceAll(" ", "")}
    }

    function handleSliderBounds (element, min, max, initialValue){

        var step = Math.pow(10, Math.floor(Math.log(max) / Math.log(10)) - 1)

        if (min == max || (max - min) < step) {
            initialValue = max
            max = max + step
            min = min - step
            element.prop("disabled", true)

            element.attr("min", min)
            element.attr("max", max)
            element.val(initialValue)
            element.attr("step", step)

        } else {

            var floorMin = Math.floor(min / step) * step
            var ceilMax = Math.ceil(max / step) * step

            element.attr("min", floorMin)
            element.attr("max", ceilMax)
            element.val(initialValue == max ? ceilMax : floorMin)
            element.attr("step", step)

        }



    }

    this.initSliderStyling = function () {

        var prSliderLabel = $('#prSliderLabel');
        var sqftSliderLabel = $('#sqftSliderLabel');
        var prRangeColor = $('#prRangeColor');
        var sqftRangeColor = $('#sqftRangeColor');

        prSliderLabel.css("color", prSliderLabel.data("prSliderTextColor"))
        sqftSliderLabel.css("color", sqftSliderLabel.data("sqftSliderTextColor"))
        prRangeColor.css("background-color", prRangeColor.data("prSliderColor"))
        sqftRangeColor.css("background-color", sqftRangeColor.data("sqftSliderColor"))

    }

    this.handleLowerPrSlider = function () {

        var lowerSlider = $('#prLower');
        var upperSlider = $('#prUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };

    this.handleUpperPrSlider = function () {

        var lowerSlider = $('#prLower');
        var upperSlider = $('#prUpper')

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    }

    this.handleLowerSqftSlider = function () {

        var lowerSlider = $('#sqftLower');
        var upperSlider = $('#sqftUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };

    this.handleUpperSqftSlider = function () {

        var lowerSlider = $('#sqftLower');
        var upperSlider = $('#sqftUpper');

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };


    function handleLowerSlider(lowerSlider, upperSlider) {

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());


        if ((lowerVal > upperVal - parseInt(lowerSlider.attr("step"))) && !lowerSlider.prop("disabled")) {
            upperSlider.val(lowerVal + parseInt(lowerSlider.attr("step")));

            if (upperVal == upperSlider.attr("max")) {
                lowerSlider.val(parseInt(upperSlider.attr("max")) - parseInt(lowerSlider.attr("step")));
            }
            lowerVal = parseInt(lowerSlider.val());
            upperVal = parseInt(upperSlider.val());
        }
        getFilterValues();
    }

    function handleUpperSlider(lowerSlider, upperSlider) {

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());

        if ((upperVal < lowerVal + parseInt(upperSlider.attr("step"))) && !upperSlider.prop("disabled")) {
            lowerSlider.val(upperVal - parseInt(upperSlider.attr("step")));

            if (lowerVal == lowerSlider.attr("min")) {
                upperSlider.val(parseInt(lowerSlider.attr("min")) + parseInt(upperSlider.attr("step")));
            }

            lowerVal = parseInt(lowerSlider.val());
            upperVal = parseInt(upperSlider.val());

        }
        getFilterValues();
    }

    this.handleSliderStyling = function () {

        var lowerPrSlider = $('#prLower');
        var lowerPrValue = $('#prAmountLower');
        var upperPrSlider = $('#prUpper');
        var upperPrValue = $('#prAmountUpper');
        var prRangeColor = $('#prRangeColor');

        manageSliderStyling(lowerPrSlider, lowerPrValue, upperPrSlider, upperPrValue, prRangeColor)

        var lowerSqftSlider = $('#sqftLower');
        var lowerSqftValue = $('#sqftAmountLower');
        var upperSqftSlider = $('#sqftUpper');
        var upperSqftValue = $('#sqftAmountUpper');
        var sqftRangeColor = $('#sqftRangeColor');

        manageSliderStyling(lowerSqftSlider, lowerSqftValue, upperSqftSlider, upperSqftValue, sqftRangeColor)

        if (upperPrSlider.prop("disabled")) {
            upperPrValue.addClass("hide")
        }

        if (upperSqftSlider.prop("disabled")) {
            upperSqftValue.addClass("hide")
        }
    }

    function manageSliderStyling(lowerSlider, lowerValue, upperSlider, upperValue, rangeColor) {

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());

        var lowerBuffer = parseInt(lowerValue.innerWidth()) * .5
        var upperBuffer = parseInt(upperValue.innerWidth()) * .5

        normalizedLower = ((lowerVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) * 100
        normalizedUpper = ((upperVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) * 100

        diff = parseInt(normalizedUpper - normalizedLower) < 15 && parseInt(normalizedUpper - normalizedLower) > 0 ? 25 : 0;

        lowerBuffer = normalizedLower > 0 ? lowerBuffer : 0
        upperBuffer = normalizedUpper < 100 ? upperBuffer : 0


        lowerValue.css("margin-left", "calc(" + normalizedLower + '% - ' + (lowerBuffer + diff) + "px)")
        upperValue.css({
            "margin-right": "calc(" + (100 - normalizedUpper) + '% - ' + (upperBuffer + diff) + "px)",
            "right": 0
        });

        // add dollar signs to the price slider range
        var dollarSymbol = "";
        if (lowerSlider[0].id.toLowerCase() === "prlower") dollarSymbol = "$";

        lowerValue.val(dollarSymbol + nFormatter(lowerSlider.val(), 1));
        upperValue.val(dollarSymbol + nFormatter(upperSlider.val(), 1));

        rangeColor.css("margin-left", normalizedLower + '%');
        rangeColor.css("width", normalizedUpper - normalizedLower + '%');
    }

    function nFormatter(num, digits) {

        var lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];

        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });

        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    this.handleFilterLayout = function () {

        var ctas = this.$element.find('.ProductFilterV2__management-ctas');
        var buttons = this.$element.find('.ProductFilterV2__management-buttons');
        var rooms = this.$element.find('.ProductFilterV2__management-rooms');
        var options = this.$element.find('.ProductFilterV2__management-options');

        if ($(window).outerWidth() < 1440) {
            ctas.removeClass("flex-md-column pl-md-9 my-6")
            ctas.addClass("justify-content-sm-center pt-6 full-width")
            buttons.addClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "none",
                "border-top": "1px solid #b9b9b9"
            });

        }
        else {
            ctas.addClass("flex-md-column pl-md-9 my-6")
            ctas.removeClass("justify-content-sm-center pt-6 full-width")
            buttons.removeClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "1px solid #b9b9b9",
                "border-top": "none"
            });
        }

        if ($(window).outerWidth() < 1025) {
            options.css("width", rooms.innerWidth() > 0 ? rooms.innerWidth() : "")
        }
        else {
            options.css("width", "")
        }

        if ($(window).outerWidth() < 1025) {
            this.$element.find('.ProductFilterV2__management-view-btn').trigger('click')
        }

        this.handleSliderStyling()
    }

    function getFilterCount() {
        var prLowerSlider = $('#prLower');
        var prUpperSlider = $('#prUpper');
        var sqftLowerSlider = $('#sqftLower');
        var sqftUpperSlider = $('#sqftUpper');
        var bedrooms = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var bathrooms = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var homeOptions = $(".ProductFilterV2__management-options-checkbox");

        var count = 0;

        if (parseInt(prLowerSlider.val()) > (parseInt(prLowerSlider.attr("min"))) || parseInt(prUpperSlider.val()) < parseInt(prUpperSlider.attr("max"))) {
            if (!prUpperSlider.prop("disabled")) {
                count = count + 1
            }

        }
        if (parseInt(sqftLowerSlider.val()) > parseInt(sqftLowerSlider.attr("min")) || parseInt(sqftUpperSlider.val()) < parseInt(sqftUpperSlider.attr("max"))) {
            if (!sqftUpperSlider.prop("disabled")) {
                count = count + 1
            }
        }
        if (bedrooms.children("input:checked").val() != "Any") {
            count = count + 1
        }
        if (bathrooms.children("input:checked").val() != "Any") {
            count = count + 1
        }
        if (getSelectedHomeOptions(homeOptions, false).length > 0) {
            count = count + 1
        }

        return count;
    }

    this.handleFilterCount = function () {
        var count = getFilterCount();

        var filterCircle = this.$element.find(".ProductFilterV2__management-filter-circle")
        var arrowIcon = $("#filterNavIconArrow");
        var closeIcon = $("#filterNavIconClose");

        if (count > 0) {
            filterCircle[0].innerHTML = count
            filterCircle.removeClass("hide");
            arrowIcon.removeClass("ml-sm-6 ml-lg-9");
            closeIcon.removeClass("ml-sm-6 ml-lg-9");
            arrowIcon.addClass("ml-sm-4");
            closeIcon.addClass("ml-sm-4");
        }
        else {
            filterCircle.addClass("hide");
            arrowIcon.addClass("ml-sm-6 ml-lg-9");
            closeIcon.addClass("ml-sm-6 ml-lg-9");
            arrowIcon.removeClass("ml-sm-4");
            closeIcon.removeClass("ml-sm-4");
        }
    }

    this.onApply = function() {
        var filters = getFilterValues();
        this.handleFilterCount();
        this.togglePriceFilter();

        var brand = $("#ProductSummaryV2CommunityGallery").data('brand');
        var state = $("#ProductSummaryV2CommunityGallery").data('state');
        var region = $("#ProductSummaryV2CommunityGallery").data('region');
        var cityNames = $("#ProductSummaryV2CommunityGallery").data('city');

        var formattedProductType = _selectedProduct.replaceAll(/[.:()-\s]/g, "").toLowerCase();
        if (formattedProductType === "communities")
            getFilteredCommunities(brand, state, region, cityNames, filters);
        else if (formattedProductType === "quickmoveins")
            getFilteredQMIs(brand, state, region, cityNames, filters);

        savedItemService.setSavedStateForDocument();
    }

    function sendFilterChangeEvent(result, filters){
        var isFiltered = getFilterCount() > 0;
        $(".pfv2-listener").trigger("pfv2-filter-change", [result, filters, isFiltered, _selectedProduct]);
    }

    function getFilterValues() {
        var prLowerSlider = $('#prLower');
        var prUpperSlider = $('#prUpper');
        var sqftLowerSlider = $('#sqftLower');
        var sqftUpperSlider = $('#sqftUpper');
        var bedrooms = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var bathrooms = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var homeOptions = $(".ProductFilterV2__management-options-checkbox");
        
        var filters = {};

        filters["PR Lower"] = parseInt(prLowerSlider.val());
        filters["PR Upper"] = parseInt(prUpperSlider.val());
        filters["Sqft Lower"] = parseInt(sqftLowerSlider.val());
        filters["Sqft Upper"] = parseInt(sqftUpperSlider.val());
        filters["Bedrooms"] = bedrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bedrooms.children("input:checked").val());
        filters["Bathrooms"] = bathrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bathrooms.children("input:checked").val());
        filters["Home Options"] = getSelectedHomeOptions(homeOptions, true);

        if (_selectedProduct) {

            if (_filterValues[_selectedProduct]) {
                filters.exactCount = _filterValues[_selectedProduct].exactCount || 0;
                filters.closeCount = _filterValues[_selectedProduct].closeCount || 0;
            }

            _filterValues[_selectedProduct] = filters;
        }

        return filters;
    }

    function setFilterValues(storedFilter) {
        // set dom values from stored filter
        var $prLowerSlider = $('#prLower');
        var $prUpperSlider = $('#prUpper');
        var $sqftLowerSlider = $('#sqftLower');
        var $sqftUpperSlider = $('#sqftUpper');
        var $bedrooms = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $bathrooms = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var $homeOptions = $(".ProductFilterV2__management-options-checkbox");
        
        var filters = _filterValues[storedFilter];

        $prLowerSlider.val(filters["PR Lower"].toString());
        $prUpperSlider.val(filters["PR Upper"].toString());
        $sqftLowerSlider.val(filters["Sqft Lower"].toString());
        $sqftUpperSlider.val(filters["Sqft Upper"].toString());

        $bedrooms.children().each(function(i, bedOption){
            var $bedOption = $(bedOption);

            if ($bedOption.val() == "Any" && filters["Bedrooms"] == 0) 
                $bedOption.prop("checked", true);

            else if ($bedOption.val() == filters["Bedrooms"].toString())
                $bedOption.prop("checked", true);

            else
                $bedOption.prop("checked", false);
        });

        $bathrooms.children().each(function(i, bathOption){
            var $bathOption = $(bathOption);

            if ($bathOption.val() == "Any" && filters["Bathrooms"] == 0) 
                $bathOption.prop("checked", true);

            else if ($bathOption.val() == filters["Bathrooms"].toString())
                $bathOption.prop("checked", true);

            else
                $bathOption.prop("checked", false);
        });

        $homeOptions.each(function (i, homeOption) {
            var $homeOption = $(homeOption);

            if (filters["Home Options"].indexOf($homeOption.val().toLowerCase()) > -1 && !$homeOption.prop('disabled')) 
                $homeOption.prop("checked", true);
            else
                $homeOption.prop("checked", false);
        });

        toggleBedroomSelection();
        toggleBathroomSelection();
        applySavedExactAndCloseMatchValues();
    }

    function getSelectedHomeOptions(homeOptions, checked) {
        var selectedHomeOptions = [];

        homeOptions.each(function (i) {
            if (homeOptions[i].checked == checked && !homeOptions[i].disabled) {
                selectedHomeOptions.push(homeOptions[i].value.toLowerCase())
            }
        });

        return selectedHomeOptions;
    }

    this.onReset = function (stopEvent) {
        this.resetFilterValues();
        this.handleFilterCount();

        var $filterPriceLocation = $(".ProductFilterV2__Price-management-view");
        if ($filterPriceLocation.hasClass("filter-open"))
            this.togglePriceFilter();

        if (!stopEvent) sendFilterChangeEvent();

        updateExactAndCloseMatchValues([]);
    }

    this.resetFilterValues = function () {
        this.initSliders();
        this.initRoomSelection();
        this.initHomeOptions();
    }

    function getFilteredCommunities(brand, state, region, cityNames, filterValues) {
        var url = '/api/Community/ProductCommunitySearch?';
        var homeTypesQuery = "";
        filterValues['Home Options'].forEach(function(type) {
            homeTypesQuery += "&" + type + "=true";
        });
        var params = 'brand=' + brand + 
                     '&state=' + state + 
                     '&region=' + region + 
                     '&cityNames=' + cityNames +
                     '&minPrice=' + filterValues['PR Lower'] + 
                     '&maxPrice=' + filterValues['PR Upper'] + 
                     '&minBedrooms=' + filterValues['Bedrooms'] + 
                     '&minBathrooms=' + filterValues['Bathrooms'] + 
                     '&pageSize=0' + homeTypesQuery +
                     '&jsonFormat=true';

        $.ajax({
            type: 'GET',
            context: this,
            url: url + params
        }).done(function(searchResult) {
            sendFilterChangeEvent(searchResult, filterValues);
            updateExactAndCloseMatchValues(searchResult);
        });
    }

    function getFilteredQMIs(brand, state, region, cityNames, filterValues) {
        var homeTypes = {
            "singlefamilyhome": "HomeTypeIsSingleFamily",
            "townhome": "HomeTypeIsTownhome",
            "condominium": "HomeTypeIsCondominium",
            "carriagehome": "HomeTypeIsCarriage",
            "gardenhome": "HomeTypeIsGarden", 
            "villa": "HomeTypeIsVilla"
        };

        var data = {
            StateName: state,
            RegionName: region,
            BrandName: brand,
            PriceMin: filterValues['PR Lower'],
            PriceMax: filterValues['PR Upper'],
            SqftMin: filterValues['Sqft Lower'],
            SqftMax: filterValues['Sqft Upper'],
            BedroomMin: filterValues['Bedrooms'],
            BathMin: filterValues['Bathrooms']
        };
        
        if (cityNames.length)
            data.CityNames = cityNames.split(",");

        filterValues['Home Options'].forEach(function(type) {
            data[homeTypes[type]] = true;
        });
        
        $.ajax({
            type: 'POST',
            context: this,
            url: '/api/qmi/inventorysearch',
            data: data,
            success: function (searchResult) {
                sendFilterChangeEvent(searchResult, filterValues);
                updateExactAndCloseMatchValues(searchResult);
            }
        });
    }

    function updateExactAndCloseMatchValues(searchResult) {
        var exactCount = 0;
        var closeCount = 0;

        searchResult.forEach(function(item) { item.isExactMatch ? exactCount++ : closeCount++ });

        $('#ExactMatchLabel').html(exactCount);
        $('#CloseMatchLabel').html(closeCount);

        if (!_filterValues[_selectedProduct]) return;

        _filterValues[_selectedProduct].exactCount = exactCount;
        _filterValues[_selectedProduct].closeCount = closeCount;
    }

    function applySavedExactAndCloseMatchValues() {
        var exactCount = _filterValues[_selectedProduct].exactCount || 0;
        var closeCount = _filterValues[_selectedProduct].closeCount || 0;

        $('#ExactMatchLabel').html(exactCount);
        $('#CloseMatchLabel').html(closeCount);
    }
}

module.exports = component(ProductFilterV2);
