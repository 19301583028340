var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var component = require('../../../lib/js/component.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');
var mapPinHelper = require('./map-pin-helper.js');
var utils = require('../../../lib/js/utils.js');

var ProductMapControllerv2 = function () {
    var currentRegion = "";

    this.initialize = function (el, options) {
        var _this = this;
        this.options = {};

        var mapScriptLoadedEventAlreadyFired = window.Pulte.isMapScriptLoaded;
        var productMapEl = $(document).find('.ProductMapV2');
        var state = productMapEl.attr("data-state");
        var region = productMapEl.attr("data-region");
        var city = productMapEl.attr("data-city");
        var brand = $('body').data('brand');
        this.brand = $('body').data('brand');
        this.$mapElement = this.$element.find('.Map-googleMap');

        $.extend(this.options, options, this.$mapElement.data());
        this.reloadRegions = true;

        //store api url if it exists
        this.apiUrl = $('body').data('api-url');
        // SVG Pin properties
        this.MARKER_PIN_SVG_SCALE_SELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_SELECTED;
        this.MARKER_PIN_SVG_SCALE_UNSELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_UNSELECTED;

        // Placeholder for reference to map instance
        this.map = {};

        // Placeholder for marker list
        this.markerList = [];
        this.markers = [];
        this.regionMarkers = {};

        // Region selected Listener
        this.$element.on('filterSelected', function (e, data) {
            _this.fetchFilter.call(_this, data);
        });

        // Listener for pin reset from Product Map
        this.$element.on('resetPinSelection', function() {
            _this.clickedPin = null;
            _this.resetMarkerSize.call(_this);
        });

        // Listener for card click from Map Sidebar
        this.$element.on('triggerPinClick', function(_,communityID) {
            var clickedMarker = _this.markerList.find(function(mrk) { 
                return mrk.communityId == communityID 
            });
            new google.maps.event.trigger(clickedMarker,'click');
        });
        

        var productLocation = {
            "brand": brand,
            "flag": "",
            "pageNumber": 0,
            "pageSize": 10,
            "productType": "community",
            "qmi": false,
            "region": region,
            "state": state,
            "cityNames": city,
            "data": "",
            "searchForExact": false
        }

        if (city) {
            productLocation.flag = "any";
            productLocation.data = city;
        } else if (region) {
            productLocation.flag = "region";
            productLocation.data = region;
        } else {
            productLocation.flag = "state";
            productLocation.data = state;
        }

        // Set Map Type
        this.options.map.pulteMapType = 'filters';

        if (mapScriptLoadedEventAlreadyFired) {   
            // Setup markers
            _this.setupMap();
        }else{
            $('body').one('MapScriptLoaded', function () {
                _this.fetchFilter.call(_this, productLocation);
                
                // Setup markers
                _this.setupMap();
            });
        }
    };

    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function () {
        var _this = this;
        // Set up map specific listener for when map is ready
        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to returned map
            _this.map = map;

            _this.setupMarkers.call(_this);
            // Remove map instance listener
            $(this).off(listener);
            google.maps.event.addListener(_this.map, "click", function (e) {
                // Added marker check for clicks on maps with no markers
                if (_this.markerList.length) {
                    _this.clickedPin = null;
                    _this.resetMarkerSize.call(_this);
                }
            });
        });
        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore);

        var mapSettings = {
            zoomControls: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
        };

        _this.map.setOptions({
            zoomControl: true,
            draggable: true,
            zoomControlOptions: mapSettings.zoomControls
        });

    };

    /**
     * get the service
     *
     */
    this.fetchFilter = function (filterSelected) {
        this.removeUndefinedProps(filterSelected);

        var flag = filterSelected.flag,
            data = filterSelected.data,
            brand = filterSelected.brand,
            state = filterSelected.state,
            qmi = filterSelected.qmi;
        
            if (currentRegion == "") currentRegion = filterSelected.region; // never change region
            filterSelected.region = currentRegion;
            params = 'brand=' + brand + '&state=' + state + '&region=' + currentRegion + '&qmi=' + qmi;

        if (flag !== 'region') {
            var cities = (data ? data : "");
            params = 'brand=' + brand + '&state=' + state + '&region=' + currentRegion + '&cityNames=' + cities + '&qmi=' + qmi;
        }

        var isQmi = filterSelected.qmi;
        var url = window.location.origin;

        if (this.apiUrl && this.apiUrl.length > 0) {
            url = this.apiUrl;
        }

        /*
            * this $.ajax function setup the map
            *
        */
        // Set loading animation
        this.startLoadingAnimation(isQmi);

        // if we are in a region, load communities
        if (currentRegion) {

            $.ajax({
                context: this,
                url: url + '/api/marker/mapmarkers?' + params,
                success: function (response) {
                    for(var i = 0; i < response.length; i++) {
                        response[i].isQmi = isQmi;
                    }
                    if (this.reloadRegions) {
                        this.loadAllPinsRequest();
                        this.reloadRegions = false;
                    }
                    var self = this;
                    setTimeout(function () {
                        if (response.length > 0) { 
                            self.mapSuccess(response);

                            self.updateMapMarkers(filterSelected);
                        }else {
                            self.mapPinsNotFound(isQmi);
                        }
                        self.endLoadingAnimation();
                    }, 300);
                }
            });
        } else { // if not, only load regions

            // load regions for state
            $.ajax({
                context: this,
                url: url + '/api/marker/mapregionmarkersbystate?brand=' + brand + '&state=' + state,
                success: function (response) {

                    this.loadAllPinsRequest();
                    var self = this;
                    if (response.length > 0) {
                        setTimeout(function () {
                            self.mapSuccess(response)
                            self.endLoadingAnimation();
                        }, 300);
                    } else {
                        self.mapPinsNotFound(false);
                    }
                }
            });
        }
    }

    /*
     * ajax mapSuccess success response
     * @param {response} response
     *
    */
    this.mapSuccess = function (response) {
        var _this = this;
        if (response) _this.markers = response;
        // clear the previous markers list
        _this.markerList = [];
        var map;
        var mapSettings = {
            mapStyles: this.options.map.styles,
            mapZoom: _this.options.map.defaults.default_zoom,
            pulteMapType: _this.options.map.pulteMapType,
            mapCenter: {
                lat: _this.options.lat,
                lng: _this.options.lng
            },
            zoomControls: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
        };
        var mapOptions = {
            keyboardShortcuts: false,
            zoomControl: true,
            scaleControl: false,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            center: mapSettings.mapCenter,
            zoom: mapSettings.mapZoom,
            styles: mapSettings.mapStyles,
            draggable: true,
            scrollwheel: false,
            zoomControlOptions: mapSettings.zoomControls,
            pulteMapType: mapSettings.pulteMapType // custom key
        };

        map = new google.maps.Map(document.getElementById('mp-id'), mapOptions);
        // reset map flag to create a new one in this context
        _this.map = map;
       
        google.maps.event.addListener(_this.map, "click", function (e) {
            // Added marker check for clicks on maps with no markers
            if (_this.markerList.length) {
                _this.clickedPin = null;
                _this.resetMarkerSize.call(_this);
            }
        });

        //setup the new .ajax map
        _this.setupMarkers.call(_this);
    };

    this.updateMapMarkers = function (requestData) {
        this.removeUndefinedProps(requestData);

        var _this = this;
        var isQmi = requestData.qmi;
        var url = window.location.origin;

        if (this.apiUrl && this.apiUrl.length > 0) {
            url = this.apiUrl;
        }

        // deep clone the data
        var requestMapMarkersData = JSON.parse(JSON.stringify(requestData));
        requestMapMarkersData.pageSize = 0;

        this.startLoadingAnimation(isQmi);

        $.ajax({
            type: 'GET',
            url: url + '/api/marker/mapmarkers',
            data: requestMapMarkersData,
            success: function (response) {

                var exactMatchCount = 0;
                var closeMatchCount = 0;

                function evaluateColorChange(marker) {

                    var iconSvgProperties = {
                        scale: _this.MARKER_PIN_SVG_SCALE_UNSELECTED,
                        brandName: marker.BrandName,
                        pinType: marker.PinType,
                        isActiveAdult: marker.IsActiveAdult
                    };

                    for (var i = 0; i < _this.markerList.length; i++) {
                        if (marker.Name === _this.markerList[i].name) {

                            // Find matching marker in markerList
                            if (marker.PinType !== _this.markerList[i].pinType) {
                                if (marker.PinType === 'exact') {
                                    _this.markerList[i].pinType = 'exact';
                                }
                                else {
                                    _this.markerList[i].pinType = 'close';
                                }

                                _this.markerList[i].setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
                            }
                        }
                    }
                }

                for (var i = 0; i < response.length; i++) {
                    evaluateColorChange(response[i]);
                    if (response[i].PinType === 'exact') {
                        exactMatchCount++;
                    }
                    else {
                        closeMatchCount++;
                    }
                }

                if (response.length === 0) _this.mapPinsNotFound(isQmi);

                _this.endLoadingAnimation();

                if (!mapPinHelper.pins.CLUSTER_2_ITEMS_PIN || !mapPinHelper.pins.CLUSTER_3_OR_MORE_ITEMS_PIN) {
                    console.log('Map Cluster Pins Disabled: Missing SVG value');
                    return;
                }

                function setPinOptions(svg) {
                    return {
                        width: 50,
                        height: 50,
                        url: ('data:image/svg+xml;base64,' + window.btoa(svg)),
                        fontFamily: 'sans-serif',
                        textSize: '13',
                        textColor: mapPinHelper.pins.CLUSTER_PINS_COUNT_TEXT_COLOR || "white"
                    };
                }

                var mcOptions = {
                    gridSize: 45, // 35 original
                    maxZoom: 15, // 60 original
                    styles:[
                        setPinOptions(mapPinHelper.pins.CLUSTER_2_ITEMS_PIN),
                        setPinOptions(mapPinHelper.pins.CLUSTER_3_OR_MORE_ITEMS_PIN),
                    ]
                };

                var pinTypeExact = _this.markerList.filter(function (m) {return m.pinType === 'exact'});
                new MarkerClusterer(_this.map, pinTypeExact, mcOptions);
            }
        });

    }

    this.removeUndefinedProps = function (obj) {
        for (var prop in obj) {
            if (obj[prop] === undefined) {
                delete obj[prop];
            }
            if (obj[prop] === '') {
                delete obj[prop];
            }
        }
    }

    this.startLoadingAnimation = function (isQmi) {
        var $LoadingMapEl = $('.Map-container .loading-map');
        $LoadingMapEl.addClass("loading-map--active").find(".loading-map__text").text($LoadingMapEl.attr("data-fh-" + (isQmi ? "qmi" : "community") + "-text"));
        $('.Map-container .no-pins-found').removeClass("no-pins-found--active");
    }

    this.endLoadingAnimation = function () {
        $('.Map-container .loading-map').removeClass("loading-map--active");
    }

    this.mapPinsNotFound = function (isQmi) {
        var $el = $('.Map-container .no-pins-found');
        $el.addClass("no-pins-found--active").find('.no-pins-found__text').text($el.attr("data-fh-" + (isQmi ? "qmi" : "community") + "-text"));
    };

    this.loadAllPinsRequest = function () {
        var self = this;
        var url = window.location.origin;

        if (this.apiUrl && this.apiUrl.length > 0) {
            url = this.apiUrl;
        }

        $.ajax({
            context: this,
            url: url + '/api/marker/mapregionmarkers?brand=' + this.brand + '&currentRegion=' + currentRegion,
            success: function (response) {
                self.regionMarkers = response;

                $(self.regionMarkers).each(function (index, item) {
                    item.kind = 'region';
                });
                self.renderMarkerOptions($(self.regionMarkers), 'region');
            }
        });
    }

    this.renderMarkerOptions = function (markersArray, fromPinType) {
        var _this = this;
        this.brandsInSearch = {};

        markersArray.each(function (i, el) {
            var marker = el;
            var position = mapsHelper.getItemPosition(marker.Latitude, marker.Longitude);
            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_UNSELECTED,
                brandName: marker.BrandName,
                pinType: marker.PinType,
                isActiveAdult: marker.IsActiveAdult
            };

            var formatedPrice = mapsHelper.formatNumber(marker.StartingFromPrice);
            var mapMkr = new google.maps.Marker({
                position: position,
                map: _this.map,
                icon: mapPinHelper.getGoogleMapPinObject(iconSvgProperties),
                name: marker.Name,
                templateType: _this.options.type,
                pinType: marker.PinType,
                isActiveAdult: marker.IsActiveAdult,
                communityId: marker.Id,
                CommunityLink: marker.CommunityLink,
                maxBedrooms: marker.MaxBedrooms,
                minBedrooms: marker.MinBedrooms,
                maxBathrooms: marker.MaxBathrooms,
                minBathrooms: marker.MinBathrooms,
                minGarage: marker.MinGarage,
                maxGarage: marker.MaxGarage,
                startingFromPrice: formatedPrice,
                inventoryCount: marker.InventoryCount,
                isQmi: marker.isQmi,
                priceStatus: marker.PriceStatus,
                communityStatus: marker.CommunityStatus,
                address: marker.Address,
                brandName: marker.BrandName,
                phone: marker.Phone,
                ctaText: marker.CtaText,
                ctaQmiText: marker.CtaQmiText,
                region: marker.Region,
                DisplayGoogleDirections: marker.DisplayGoogleDirections
            });

            if (fromPinType === 'region' || fromPinType === 'state') {
                mapMkr.kind = fromPinType;
            }
            // Update the maker data to reflect new Google Maps formated position
            marker.position = position;

            // Store updated marker
            _this.markerList.push(mapMkr);

            if (marker.PinType) {
                if (marker.PinType === "region") {
                    mapsHelper.makeInfoWindow.call(_this, mapMkr);
                    mapsHelper.styleInfoWindows.call(_this, mapMkr);
                } else {
                    _this.updateBrandsInSearch(marker.BrandName, marker.IsActiveAdult);
                    _this.trigger('pmap-loadMapLegend', _this.brandsInSearch);
                    _this.addMarkerAjaxHoverEvents.call(_this, mapMkr, fromPinType);
                }
            } 
            
            _this.addMarkerAjaxClickEvent.call(_this, mapMkr, fromPinType);
        });
    }

    /**
    * Setup and add markers to map
    */
    this.setupMarkers = function () {
        var _this = this;

        this.renderMarkerOptions($(_this.markers), 'pin');
        if (!$.isEmptyObject(_this.regionMarkers)) {
            this.renderMarkerOptions($(_this.regionMarkers), 'region');
        }

        // Recenter map to include all markers
        this.fitToBounds.call(_this, _this.markers);
    };

    /**
     * addMarkerClickEvent: Attach click event to get a new URL
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerClickEvent = function (mapMkr) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {
            window.location.href = this.CommunityLink;
        });
    };

    /**
     * addMarkerAjaxClickEvent: Attach click event to get a new URL
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerAjaxClickEvent = function (mapMkr, fromPinType) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {

            // Reset the pins if we're dealing with a region pin or switching from one pin to another, or interrupt the mouseover timeout.
            if (this.pinType === 'region' || _this.clickedPin !== this || _this.pinLoadTimeout) {
                clearTimeout(_this.pinLoadTimeout);

                // - Make all markers small
                _this.resetMarkerSize.call(_this);

                var iconSvgProperties = {
                    scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                    brandName: this.brandName,
                    pinType: this.pinType,
                    isActiveAdult: this.isActiveAdult
                };

                this.setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));

                // Close any open infoWindows
                mapsHelper.closeAllInfoWindows.call(_this);
            }

            if (this.pinType !== 'region') {
                // Only trigger the sidebar if swapping pins or interrupting the mouseover timeout
                if (_this.clickedPin !== this || _this.pinLoadTimeout) {
                    var product = {
                        communityId: mapMkr.communityId,
                        isQmi: mapMkr.isQmi
                    }
                    _this.trigger('pinSelected', product);
                    _this.trigger('pinClicked', product);

                    pinClickEvent = {
                        event: AdobeLaunch.MAP_PIN_CLICK,
                        community: { communityID: mapMkr.communityId, brandName: mapMkr.brandName }
                    };

                    AdobeLaunch.pushToDataLayer(pinClickEvent);
                }
            } else {
                // Open this markers infoWindow
                this.setZIndex(999);
                this.iw.open(_this.map, this);

                $('.gm-style-iw').addClass('openIW');
            }

            _this.pinLoadTimeout = null;
            _this.clickedPin = this;
        });
    };

    /**
     * addMarkerAjaxHoverEvent: Attach click event to get a new URL
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerAjaxHoverEvents = function (mapMkr, fromPinType) {
        var _this = this;

        //mouseover
        google.maps.event.addListener(mapMkr, 'mouseover', function (e) {
            if (!_this.clickedPin) {
                _this.pinLoadTimeout = setTimeout(function() {
                    // - Make all markers small
                    _this.resetMarkerSize.call(_this);

                    var iconSvgProperties = {
                        scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                        brandName: mapMkr.brandName,
                        pinType: mapMkr.pinType,
                        isActiveAdult: mapMkr.isActiveAdult
                    };

                    mapMkr.setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));

                    var product = {
                        communityId: mapMkr.communityId,
                        isQmi: mapMkr.isQmi
                    }
                    _this.trigger('pinSelected', product);
                    _this.pinLoadTimeout = null;
                }, 250);
            }
        });

        //mouseout
        google.maps.event.addListener(mapMkr, 'mouseout', function (e) {
            if (!_this.clickedPin) {
                clearTimeout(_this.pinLoadTimeout);
                _this.pinLoadTimeout = null;
                _this.resetMarkerSize.call(_this);
            }
        });
    };

    this.resetMarkerSize = function () {
        var _this = this;
        _this.trigger('closeProductSelection');
        $(this.markerList).each(function (i) {
            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_UNSELECTED,
                brandName: this.brandName,
                pinType: this.pinType,
                isActiveAdult: this.isActiveAdult
            };

            _this.markerList[i].setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
        });

        // Close any open infoWindows
        mapsHelper.closeAllInfoWindows.call(_this);
    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        this.map.fitBounds(bounds);
    };

    this.updateBrandsInSearch = function (brandString, isActiveAdult) {
        var brand = utils.getBrand(brandString);

        switch(brand) {
            case utils.brands.PULTE:
                if (isActiveAdult) {
                    this.brandsInSearch.pulteActiveAdult = true;
                } else {
                    this.brandsInSearch.pulte = true; 
                }
                break;
            case utils.brands.CENTEX:
                this.brandsInSearch.centex = true; 
                break;
            case utils.brands.DELWEBB:
                this.brandsInSearch.delwebb = true; 
                break;
            case utils.brands.DIVOSTA:
                this.brandsInSearch.divosta = true; 
                break;
            case utils.brands.JW:
                this.brandsInSearch.jw = true; 
                break;
            case utils.brands.AMERICANWEST:
                this.brandsInSearch.americanwest = true;
                break;
        }
    }

    this.setMapPinIcon = function ($element, iconSvgProperties) {
        iconSvgProperties.scale = .75;
        var mapPinProperties = mapPinHelper.getGoogleMapPinObject(iconSvgProperties);
        $element.attr({ 'src': mapPinProperties.url, 'width': mapPinProperties.scaledSize.width, 'height': mapPinProperties.scaledSize.height });
    }

}
module.exports = component(ProductMapControllerv2);
