var component = require('../../../../lib/js/component.js');
var utils = require('../../../../lib/js/utils.js');

var CompareResults = function () {

    var compareTypeMap = {};

    this.initialize = function (element, options) {

        var _this = this;
        var $title = _this.$element.find(".CompareResults__title");
        var modalCloseEvent = 'hidden.bs.modal';
        compareTypeMap["plan"] = $title.data("comparePlanTitle");
        compareTypeMap["qmi"] = $title.data("compareQmiTitle");
        compareTypeMap["community"] = $title.data("compareCommunityTitle");
        
        _this.$element.on("compare-results", this.handleCompare.bind(this));

        var $compareResultsModal = $("#modal-compare-results");
        var modalOpenEvent = 'shown.bs.modal';

        $compareResultsModal.on(modalOpenEvent, function (e) {
            var $activeCompareCards = $('.CompareResults__card.active');
            var $modalCarousels = $activeCompareCards.find(".owl-carousel");
            $modalCarousels.trigger("refresh.owl.carousel");
        });

        $compareResultsModal.on(modalCloseEvent, function (e) {
            _this.$element.find('.popover-close-btn').trigger('click');
        });
    }

    this.handleCompare = function(event, view, ids){
        view = view.toLowerCase();

        var $allCards = this.$element.find(".CompareResults__card");
        $allCards.addClass("d-none");

        var $allCommunityCards = this.$element.find(".CompareResults__cardRow");
        $allCommunityCards.addClass("d-none").removeClass("active grid-item");

        var $title = this.$element.find(".CompareResults__title");
        $title.text(compareTypeMap[view]);

        var $allCardsToFilter = view == "community" ? $allCommunityCards : $allCards;
        var $showCards = $allCardsToFilter.filter( 
            function() {
                return $(this).data("cardType") == view && ids.includes($(this).data("cardId").toString());
            }
        );

        if(view == "qmi"){
            $showCards.find(".CompareResults__contentContainer").removeClass("plan-card-override");
        }

        $showCards.removeClass("d-none two-cards three-cards").addClass("active");
        var $cardsParent = $('.CompareResults__resultsContainer')

        if(view == "community"){
            $showCards.addClass("grid-item");
            $cardsParent.removeClass('two-card three-card');
            ids.length == 2 ? $('.CompareResults__resultsContainer').addClass("two-card") : $cardsParent.addClass("three-card");
            orderCardsForCommunityCompare($showCards, ids);
        }
        else{
            $showCards.length == 2 ? $showCards.addClass("two-cards") : $showCards.addClass("three-cards");
            orderCardsBySelectedIds($showCards, ids);
        }
    }

    function getMaxHeightBulletsContainer(){
        // Calculate height of the bullet row and set each row to be the height of the tallest row
        var maxHeight = 0;
      
        // Find the maximum height among the elements
        $(".CompareResults__bullets").each(function() {
          maxHeight = Math.max(maxHeight, $(this).height() + 48);
        });
      
        // Set the maximum height to all elements
        $(".features-wrapper").css('min-height',maxHeight);
      }
      
    function orderCardsBySelectedIds($showCards, ids) {
        var $sorted = [];
        $showCards.each(function () {
            var newIndex = ids.indexOf($(this).data("cardId").toString());
            $sorted[newIndex] = $(this);
        });
    
        $cardsParent = $('.CompareResults__resultsContainer');
        for (var i = 0; i < $sorted.length; i++) {
            var card = $sorted[i];
            $cardsParent.append(card);
        }

        setTimeout(getMaxHeightBulletsContainer,0);
    }

    function orderCardsForCommunityCompare($showCards, ids) {
        var $rows = [];

        $cardsParent = $('.CompareResults__resultsContainer');

        for(var x=0; x<ids.length; x++){
            $rows = $showCards.filter( 
                function() {
                    return $(this).data("cardId").toString() == ids[x];
                }
            );
            $cardsParent.append($rows);
        }
    }
}

module.exports = component(CompareResults);