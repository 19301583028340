var component = require('../../../lib/js/component.js');

function PlanInteractiveTool() {
    this.initialize = function(element) {
        var _this = this;
        window.setUpTabs = true;
        window.isFirstLoad = true;
        this.$interactiveTool = this.$element.find('.PlanInteractiveTool__InteractiveTool');
        this.$staticFloorPlan = this.$element.find('.PlanInteractiveTool__StaticFloorPlan');
        this.$tabContainers = this.$element.find('.PlanInteractiveTool__tab-container');
        this.$tabs = this.$element.find('.PlanInteractiveTool__tab');
        this.$mobileHeaders = this.$element.find('.PlanInteractiveTool__mobile-header');
        this.$mobileTabsContainer = this.$element.find('.PlanInteractiveTool__mobile-tabs');
        this.$mobileTabs = this.$element.find('.PlanInteractiveTool__mobile-tab');
        this.$tabs.on('click', this.tabClick.bind(this));
        this.$mobileTabs.on('click', this.tabClick.bind(this));
        this.$iframe = this.$element.find('.PlanInteractiveTool__iframe');
        this.$iframe.on('load', this.iframeLoaded.bind(this));
        this.$iframe.on('apiLoaded', this.setUpTabs.bind(this));
        this.$printBtn = this.$element.find(".PlanInteractiveTool__utilities--print");
        this.$saveBtn = this.$element.find(".PlanInteractiveTool__utilities--save");

        $(this.$printBtn).on("click", function () {
            window.avAPI.printBrochure()
        });
		
		$(this.$saveBtn).on("click", function () {
            var $saveButton = $(this).find('a');
            var $saveButtonImg = $(this).find('img');
            var isSaved = $saveButtonImg.attr('data-is-saved').toLowerCase() === "true";

            var user = window.Pulte.user;
            var sessionId = window.Pulte.SessionId ? window.Pulte.SessionId : "";
            var userId = user ? user.UserId : "";   

            if (!isSaved) {
                window.avAPI.saveConfig(sessionId, userId, "callBackSaveFP");
            } else {
                window.avAPI.deleteConfig(userId);
            }
        });

        this.handleUtilityBtnNavigation();
    }

    this.iframeLoaded = function(event) {
        // "window" rather than "this" because avAPI needs to be global to work.
        window.avAPI = new VpsApi(document.getElementById('PlanInteractiveToolIframe'), 'avAPICallback');
        // Because the avAPI needs the iframe to be visible at the beginning, but when there are no modules in AV, the callback
        // method never gets called, so this ensures the module won't get displayed in that situation.
        if(window.isFirstLoad) {
            this.$interactiveTool.addClass('d-none');
        }
    }

    this.setUpTabs = function() {
        window.setUpTabs = false;
        var $validTabs = [];
        var $validMobileTabs = [];
        if(window.avAPI.modulesList) {
            if(window.avAPI.modulesList.length) {
                for(var i = 0; i < this.$tabContainers.length; i++) {
                    var $tab = $(this.$tabContainers[i]);
                    var tabModule = $tab.find('.PlanInteractiveTool__tab').data('module-id');
                    if(window.avAPI.modulesList.includes(tabModule)) {
                        $validTabs.push($tab);
                        $validMobileTabs.push($(this.$mobileTabs[i]));
                    }
                    else {
                        $tab.remove()
                    }
                }
            } else {
                // When the Customize Plan tab is the only valid one, the avAPI returns an empty modulesList.
                $validTabs.push($(this.$tabContainers[0]));
                $validMobileTabs.push($(this.$mobileTabs[0]));
            }
        }

        if($validTabs.length) {
            this.$staticFloorPlan.addClass('d-none');
            this.setUpValidTabs($validTabs, $validMobileTabs);
            this.updateTabsAndRebind();
        } else {
            this.$interactiveTool.addClass('d-none');
        }
    }

    this.updateTabsAndRebind = function () {    
        var _this = this;
        var $tabs = this.$element.find('.PlanInteractiveTool__tab[role="tab"]');
        var $tabList = this.$element.find('.PlanInteractiveTool__tabs[role="tablist"]');
    
        var tabFocus = 0;
    
        $tabList.on("keydown", function(e) {
            if (e.keyCode === 13) { // Check for Enter key (keyCode 13)
                e.preventDefault(); 
                _this.tabClick({ currentTarget: $tabs[tabFocus] });
            }
    
            if (e.keyCode === 39 || e.keyCode === 37) { 
                // Right (39) or Left (37) arrow keys
                if (e.keyCode === 39) {
                    tabFocus++;
                    if (tabFocus >= $tabs.length) {
                        tabFocus = 0; 
                    }
                } else if (e.keyCode === 37) {
                    tabFocus--;
                    if (tabFocus < 0) {
                        tabFocus = $tabs.length - 1; 
                    }
                }
    
                $tabs.eq(tabFocus).attr("tabindex", "0").focus(); // Set focus on the new tab
            }
        });
    
        // Update tabFocus when a tab gains focus (for Tab key navigation)
        $tabs.on("focus", function() {
            tabFocus = $tabs.index(this);
        });
    
        $tabs.on('click', this.tabClick.bind(this));
    };
    
    this.handleUtilityBtnNavigation = function() {

        // Get all focusable utility buttons within the utility container (Save, Share, Print)
        var $utilityButtons = this.$element.find('.PlanInteractiveTool__utilities a');
        var utilityBtnFocus = 0;
    
        $utilityButtons.attr('tabindex', 0);
    
        this.$element.find('.PlanInteractiveTool__utilities').on('keydown', function(e) {
            if (e.keyCode === 13) { // Enter key
                e.preventDefault();
                // Manually trigger 'click' on the focused <a> element when the Enter key is pressed
                $utilityButtons.eq(utilityBtnFocus).trigger('click');
            }

            if (e.keyCode === 39) { // Right Arrow key
                utilityBtnFocus++;
                if (utilityBtnFocus >= $utilityButtons.length) {
                    utilityBtnFocus = 0;
                }
                $utilityButtons.eq(utilityBtnFocus).focus();
            }
    
            if (e.keyCode === 37) { // Left Arrow key
                utilityBtnFocus--;
                if (utilityBtnFocus < 0) {
                    utilityBtnFocus = $utilityButtons.length - 1;
                }
                $utilityButtons.eq(utilityBtnFocus).focus();
            }
        });

        $utilityButtons.on('focus', function() {
            // Update utilityBtnFocus to the currently focused button
            utilityBtnFocus = $utilityButtons.index(this); 
        });
    };
     
    this.setUpValidTabs = function($validTabs, $validMobileTabs) {
        var tabColumns = 12 / $validTabs.length;
        var tabColumnClass = 'col-sm-' + tabColumns;

        var mobileTabColumns = 12 / $validMobileTabs.length;
        var mobileTabColumnClass = 'col-xs-' + mobileTabColumns;

        if($validMobileTabs.length == 1) {
            this.$mobileTabsContainer.addClass('d-none');
        }

        if (this.$tabs.length > 5) {
            this.$tabs.addClass('vertical-layout');
        } else {
            this.$tabs.removeClass('vertical-layout');
        }

        for(var i = 0; i < $validTabs.length; i++) {
            var $tab = $validTabs[i];
            var $mobileTab = $validMobileTabs[i];
            this.setUpTab($tab, $mobileTab, tabColumnClass, i);
        }

        var $owlCarousel = this.$element.find('.int-carousel');

        // For mobile breakpoint
        if($validMobileTabs.length <= 3) {
            $owlCarousel.css('width',$('.PlanInteractiveTool').outerWidth())
                .removeClass('owl-carousel owl-theme')
                .children().first().css('border-left', 'none');

            this.$mobileTabs.addClass(mobileTabColumnClass);
            this.$mobileTabsContainer.css('width', 'fit-content');
        }
        else {
            // Setting up left/right arrow for mobile tab carousel
            $('.PlanInteractiveTool__slider-nav__prev, .PlanInteractiveTool__slider-nav__next').removeClass('d-none');
            $('.PlanInteractiveTool__mobile-slider').css('width', '80%');
            this.$mobileTabsContainer.css('width',$('.PlanInteractiveTool').outerWidth());

            $owlCarousel.addClass('mx-3');

            $owlCarousel.owlCarousel({
                loop: true,
                dots: false,
                autoHeight: true,
                nav: false,
                items: 3,
                center: true,
                stagePadding: -40
            });

            var mobileTabWidth = $owlCarousel.outerWidth()/3;
            $('.PlanInteractiveTool .mobile-slider .owl-item').css('width', mobileTabWidth + 'px');

            this.$element.find('.PlanInteractiveTool__slider-nav__next').on('click', function (event) {
                event.stopPropagation();
                $owlCarousel.trigger('next.owl.carousel');
            });

            this.$element.find('.PlanInteractiveTool__slider-nav__prev').on('click', function (event) {
                event.stopPropagation();
                $owlCarousel.trigger('prev.owl.carousel');
            });

            // Remove all invalid tabs b/c owl carousel creates placeholder spots for them 
            $('div.PlanInteractiveTool__mobile-tab.invalid').parent().remove();
        }
    }

    this.setUpTab = function($tabContainer, $mobileTab, tabColumnClass,iteration) {
        $tabContainer.removeClass('d-none');
        $tabContainer.addClass(tabColumnClass);
        $mobileTab.removeClass('invalid');

        if(iteration == 0) {
            var $tab = $tabContainer.find('.PlanInteractiveTool__tab');
            window.avAPI.showModule($tab.data('module-id'));
            $tab.addClass('selected');
            var tabIndex = $mobileTab.data('tab-index');
            $(this.$mobileHeaders[tabIndex]).removeClass('d-none');
            $mobileTab.addClass('selected');
        }
        $mobileTab.removeClass('d-none');
    }

    this.tabClick = function(element) {
        var clickedIndex = $(element.currentTarget).data('tab-index');
        var clickedModuleId = $(element.currentTarget).data('module-id');

        for(var i = 0; i < this.$tabs.length; i++) {
            var $tab = $(this.$tabs[i]);
            var $mobileHeader = $(this.$mobileHeaders[i]);
            var $mobileTab = $(this.$mobileTabs[i]);

            if(i == clickedIndex) {
                if(!$tab.hasClass('selected')) {
                    $("div[data-module-id='" + clickedModuleId +"']").addClass('selected');
                }

                if($mobileHeader.hasClass('d-none')) {
                    $mobileHeader.removeClass('d-none');
                }

                if(!$mobileTab.hasClass('selected')) {
                    $mobileTab.addClass('selected');
                }
            } else {
                if($tab.hasClass('selected')) {
                    var moduleId = $tab.data('module-id');
                    $("div[data-module-id='" + moduleId +"']").removeClass('selected');
                }

                if(!$mobileHeader.hasClass('d-none')) {
                    $mobileHeader.addClass('d-none');
                }

                if($mobileTab.hasClass('selected')) {
                    $mobileTab.removeClass('selected');
                }
            }
        }

        window.avAPI.showModule(clickedModuleId);
    }

    // "window" rather than "this" because avAPI requires the callback method to be global.
    window.avAPICallback = function(event) {
        if(window.isFirstLoad) {
            // On the first load, with the iframe being invisible initially, sometimes this will cause the Customize Plan module
            // to not display properly. Now that we know the callback function will be called, we can reload with the iframe being
            // visible the whole time.
            $('.PlanInteractiveTool__InteractiveTool').removeClass('d-none');
            window.isFirstLoad = false;
            window.avAPI.reload();
        }

        // The first time the avAPI calls back, the messageId is "S_002" but modules aren't available until subsequent callback
        // when the messageId is "S_001". Not checking for "S_001" specifically in case there's an error and the status is never 
        // "S_001" but some error status code. The check for "setUpTabs" boolean is so the tabs are only set up once.
        if(event.messageId != 'S_002' && window.setUpTabs) {
            // Because "this" doesn't exist here.
            $('.PlanInteractiveTool__iframe').trigger('apiLoaded');
        }

        // Trigger loadConfig after iframe loaded and API is fully initialized
        var userId = window.Pulte.user ? window.Pulte.user.UserId : "";
        if (userId) {
            window.avAPI.loadConfig(userId, "callBackLoadFP");
        }
    }
}

module.exports = component(PlanInteractiveTool);
